import React, { Component } from 'react'
import { Provider } from 'react-contextual'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime'
import ErrorBoundary from '@argos/error-boundary'
import debounce from '@argos/utils/esnext/debounce'
import canUseDom from '@argos/utils/esnext/canUseDom'
import { doesCookieExist } from '../../helpers/cookiesHelper'

import HSTSPixel from '../../components/Security/HSTSPixel'
import { BackDrop, MiniHeader, Logo, SkipLink } from '../../components'
import { User, Navigation, Search, Suggestions, TopBar } from '../../containers'
import { MegaNavService, MegaButtonsService } from '../../services'
import { NavigationStore, UserStore } from '../../stores'

import AskTrevor from '../../components/AskTrevor/AskTrevor'
import AskTrevorCTAButton from '../../components/AskTrevor/AskTrevorCTAButton'
// import AskTrevorFloatingCTA from '../../components/AskTrevor/AskTrevorFloatingCTA'

import styles from './Header.scss'

const askTrevorEnabled = false

export default class Header extends Component {
  static propTypes = {
    catalogId: PropTypes.number,
    dualBrand: PropTypes.bool,
    langId: PropTypes.number,
    miniHeader: PropTypes.bool,
    miniHeaderContent: PropTypes.element,
    onCisCookieCreated: PropTypes.func,
    onUserInfoLoad: PropTypes.func,
    overrideOnSearchSubmit: PropTypes.func,
    overrideSearchTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    overrideTrolleyCount: PropTypes.number,
    overrideWishlistCount: PropTypes.oneOfType([PropTypes.number, PropTypes.element]),
    routes: PropTypes.shape({
      help: PropTypes.func,
      stores: PropTypes.func,
      'advice-and-inspiration': PropTypes.func,
      search: PropTypes.func,
      megaButtons: PropTypes.arrayOf(PropTypes.func),
    }),
    skipToContentTag: PropTypes.string,
    sticky: PropTypes.bool,
    storeId: PropTypes.number,
    triBrand: PropTypes.bool,
    trolleyCountChange: PropTypes.number,
  }

  static defaultProps = {
    miniHeader: false,
    miniHeaderContent: null,
    routes: {},
    overrideTrolleyCount: null,
    overrideWishlistCount: null,
    onCisCookieCreated: () => ({}),
    onUserInfoLoad: () => ({}),
    storeId: 10151,
    langId: 110,
    catalogId: 10001,
    skipToContentTag: '',
  }

  static async getInitialProps() {
    const taxonomy = await MegaNavService('http://localhost:3000')
    const megaButtons = await MegaButtonsService()
    return { taxonomy, megaButtons }
  }

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
    this.state = {
      height: 0,
    }
  }

  get store() {
    return { ...global.__INITIAL_HEADER_STATE__, ...this.props }
  }

  componentDidMount() {
    if (this.props.sticky) {
      this.setHeight()
      window.addEventListener('resize', debounce(this.setHeight, 100))
    }
  }

  setHeight = () => {
    const height = this.headerRef.current ? this.headerRef.current.clientHeight : 0
    this.setState({ height })
  }

  render() {
    const {
      miniHeader,
      miniHeaderContent,
      onCisCookieCreated,
      onUserInfoLoad,
      overrideTrolleyCount,
      overrideWishlistCount,
      routes,
      skipToContentTag,
      sticky,
      dualBrand,
      triBrand,
      trolleyCountChange,
    } = this.props

    const { height } = this.state

    const skipLinks = [
      {
        id: 'skiptocontent',
        name: 'Skip to Content',
        skipTo: skipToContentTag,
      },
    ]

    /* Stripped down header for AFS, checkout etc */
    if (miniHeader) {
      return (
        <ErrorBoundary>
          <MiniHeader
            miniHeaderContent={miniHeaderContent}
            skipLinks={skipLinks}
            sticky={sticky}
            headerRef={this.headerRef}
            routes={routes}
            dualBrand={dualBrand}
            triBrand={triBrand}
          />
          {sticky && <div style={{ marginTop: height }} />}
          <HSTSPixel />
        </ErrorBoundary>
      )
    }

    const trevorEnabled = askTrevorEnabled && canUseDom() && doesCookieExist('ARGOS_TREVOR_BETA')

    return (
      <ErrorBoundary>
        <Provider {...this.store}>
          <Provider store={NavigationStore}>
            <Provider store={UserStore}>
              <header
                id='haas-v2'
                className={`${styles.header}${sticky ? ` ${styles['header--sticky']}` : ''}`}
                ref={this.headerRef}>
                {skipLinks && skipLinks.length > 0
                  ? skipLinks.map((skipLink) => (
                      <SkipLink key={skipLink.id} id={skipLink.id} name={skipLink.name} skipTo={skipLink.skipTo} />
                    ))
                  : null}
                <TopBar />
                <div className={styles.main}>
                  <div className={styles.container}>
                    <Logo routes={routes} />
                    <Navigation />
                    <div className={styles.searchContainer}>
                      <Suggestions>
                        <Search />
                      </Suggestions>
                      {trevorEnabled && <AskTrevorCTAButton />}
                    </div>
                    <User
                      onUserInfoLoad={onUserInfoLoad}
                      onCisCookieCreated={onCisCookieCreated}
                      overrideTrolleyCount={overrideTrolleyCount}
                      overrideWishlistCount={overrideWishlistCount}
                      trolleyCountChange={trolleyCountChange}
                      blackFriday={false}
                    />
                    {trevorEnabled && (
                      <ErrorBoundary>
                        <AskTrevor />
                        {/* <AskTrevorFloatingCTA /> */}
                      </ErrorBoundary>
                    )}
                  </div>
                </div>
              </header>
              {sticky && <div style={{ marginTop: height }} />}
              <BackDrop />
              <HSTSPixel />
            </Provider>
          </Provider>
        </Provider>
      </ErrorBoundary>
    )
  }
}
